.divdrop {
  padding: 60px;
  text-align: center;
  color: white;
  border: #ddd dashed;
}

@media (styles) {
  .sideBarHeight {
    height: "100%";
  }
  .menuIcon {
    float: "right";
    margin: "10px";
  }
}

#divLeft {
  position: relative;
  width: 100%;
  float: left;
}

#divRight {
  width: 48%;
  float: right;
}

.App {
  background: #30343f;
  overflow-y: auto;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  color: white;
}

body {
  margin: 0;
  padding: 20px;
  font-family: sans-serif;
}

* {
  box-sizing: border-box;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid {
  display: grid;
}

.table td {
  padding: 15px;
  border: 1px solid #ddd;
  color: skyblue;
  text-align: center;
  font-size: 16px;
}

.table th {
  background-color: aliceblue;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (max-width: 900px) {
  .table thead {
    display: none;
  }

  .table,
  .table tbody,
  .table tr,
  .table td {
    display: block;
    width: 100%;
  }
  .table tr {
    margin-bottom: 15px;
  }
  .table td {
    text-align: right;
    padding-left: 50%;
    text-align: right;
    position: relative;
  }
  .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.headerButtonContainer {
  display: flex;
  background: green;
  justify-self: flex-start;
  flex-direction: column;
  justify-content: center;
  height: "100%";
}

.inputContainer {
  margin: auto;
  display: flex;
  justify-self: flex-end;
  flex-direction: column;
  justify-content: center;
}

.uuidInput {
  width: 100px;
  height: 20px;
}

.uuidContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 20px;
}

.base64Container {
  display: flex;
  flex-direction: row;
  gap: 15px;
  color: white;
  margin: 25px;
}
.map-container{
  width: 100%;
  height: 100vh;
}