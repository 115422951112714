#map {
  height: 100%;
}

/* Optional: Makes the sample page fill the window.  */

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.map-container{
  width: 100%;
  height: 85vh;
}
#floating-panel {
  position: absolute;
  top: 10px;
  left: 25%;
  z-index: 5;
  background-color: #fff;
  padding: 5px;
  border: 1px solid #999;
  text-align: center;
  font-family: "Roboto", "sans-serif";
  line-height: 30px;
  padding-left: 10px;
}

#floating-panel {
  background-color: #fff;
  border: 1px solid #999;
  left: 25%;
  padding: 5px;
  position: absolute;
  top: 10px;
  z-index: 5;
}